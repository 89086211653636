<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
	:style="myStyle"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <!-- <v-divider class="mb-1" /> -->

	<v-list
		class="pa-0"
		style="height: 75px;"
		:style="[theme === 'safebanker' ? { 'background-color' : '#1f3159'} : { 'background-color': '#4e4e4e;' }]"
	>
      <v-list-item class="justify-center">
          <v-img
            :src="logo"
			max-width="130"
			class="mt-3 mb-2"
          />
      </v-list-item>
    </v-list>

    <!-- <v-divider class="mb-2" /> -->

	<!-- Send ENS -->
	<!-- <v-list v-if="ens">
		<div class="pa-2">
			<v-btn rounded class="black--text" elevation="1" color="secondary" @click="emergencyDialog = true">
			<v-icon left color="error">
			mdi-telegram
			</v-icon>
			{{ $t('sendEns') }}
			</v-btn>
		</div>
    </v-list> -->
	<!-- End Send ENS -->

	<v-list expand class="no-padding">
		<template v-for="(item, i) in computedItems">
			<v-list-group
				v-if="item.children"
				:key="`item-parent-${i}`"
				:active-class="`primary white--text`"
				:value="expand_reports"
			>
				<template v-slot:activator>
					<v-list-item :active-class="`primary white--text`">
						<v-icon>{{ item.icon }}</v-icon>
						<v-list-item-title class="ml-5">{{ item.title }}</v-list-item-title>
					</v-list-item>
				</template>

				<v-list-item
					v-for="(child, i) in item.children"
					:key="`group-${i}`"
					link
					:to="child.to"
					:active-class="`primary white--text`"
				>
					<v-icon class="ml-10">{{ child.icon }}</v-icon>
					<v-list-item-title class="ml-5">{{ child.title }}</v-list-item-title>
				</v-list-item>

			</v-list-group>
			<v-list-item v-else link :to="item.to" :key="`item-${i}`" :active-class="`primary white--text`">
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-title >{{ item.title }}</v-list-item-title>
			</v-list-item>
		</template>
		<VideoCallsList v-if="!webrtcErrors"></VideoCallsList>
		<div v-else>
			<v-list three-line>
			<v-list-item style="background: #f00">
				<v-list-item-avatar>
				<v-icon
					color="white"
					large
					dark
				>
					mdi-alert-circle
				</v-icon>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>Unsupported Browser</v-list-item-title>
					<v-list-item-subtitle>
						Cannot answer calls<br>
						<v-dialog v-model="webrtcErrorDialog" width="400">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="red lighten-1" class="mt-1" small v-bind="attrs" v-on="on">
									more info
								</v-btn>
							</template>
							<v-card>
								<v-card-title class="text-h5">
									Unsupported Browser
								</v-card-title>
								<v-card-text>
									Cannot answer video calls in this browser. Please use a recent version of Google Chrome, Mozilla Firefox, or Microsoft Edge.
								</v-card-text>
								<v-card-actions>
									<v-spacer />
									<v-btn color="primary" @click="webrtcErrorDialog = false">OK</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			</v-list>
		</div>
		<ChatList></ChatList>
    </v-list>

	<!-- CHECK INTERNET STATUS -->
	<template>
		<vueInternetChecker
			@status="status"
			@event="event"
		/>
    </template>
	<!-- END CHECK INTERNET STATUS -->

	<EmergencyDialog v-if="emergencyDialog" :dialog="emergencyDialog" @emergency-dialog-closed="emergencyDialog=false"></EmergencyDialog>
    <template v-slot:append>
		<div class="mb-1" v-if="ens" style="border-top: 0px solid white; border-bottom: 0px solid white; border-radius: 12px; margin-bottom: 5px;">
			<v-btn id="ensBtn" elevation="4" text block @click="emergencyDialog = true" class="text-left" style="height: 46px; justify-content: flex-start; border-radius: 10px; background-color: rgb(31, 49, 89);">
				<v-icon left color="error" class="ml-3">
					mdi-send
				</v-icon>
				<span class="ml-2">{{ $t('sendEns') }}</span>
			</v-btn>
		</div>
		<v-card
			class="mx-auto mb-2"
			outlined
			color="primary white--text"
			v-if="roleId === 12"
		>
			<v-list-item three-line>
				<v-list-item-icon class="mt-9">
					<v-icon>mdi-office-building</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title class="headline mb-1" v-if="selectedOrganisation">
					{{ selectedOrganisation.Name }}
					</v-list-item-title>
					<v-list-item-subtitle class="headline mb-1" v-if="selectedOrganisation">
					{{ selectedOrganisation.PhoneNumber }}
					</v-list-item-subtitle>
					<v-list-item-subtitle v-if="selectedOrganisation">{{ selectedOrganisation.FormatedAddress }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-card>

		<div class="mb-1 ml-5">
			<span class="ml-3 mr-3 mb-5 font-weight-light white--text body-2 text-center">Internet status: {{ internetStatus }} </span>
			<Blink v-if="internetStatus === 'Disconnected'"><v-icon color="error" medium>mdi-lan-disconnect</v-icon></Blink>
			<v-icon v-if="internetStatus === 'Connected'" color="green" medium>mdi-lan-connect</v-icon>
		</div>
		<v-divider class="white"></v-divider>
		<div class="body-2 font-weight-light py-1 text-center white--text">
			&copy; {{ new Date().getFullYear() }} SaferMobility, LLC
		</div>
		<div class="body-2 font-weight-light py-1 text-center white--text">
			<v-icon>mdi-18px mdi-web</v-icon>
			{{ trimmedAppVersion }}
			<v-icon>mdi-18px mdi-android</v-icon>
			{{ androidAppVersion }}
			<v-icon>mdi-18px mdi-apple</v-icon>
			{{ iosAppVersion }}
		</div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
	mapState,
} from 'vuex';
import EmergencyDialog from '@/views/components/EmergencyDialog.vue';
import VideoCallsList from '@/views/components/VideoCallsList';
import ChatList from '@/views/components/ChatList';
import vueInternetChecker from 'vue-internet-checker';
import Blink from 'vue-blink';

const { DARK_LOGO } = require(`@/themes/${process.env.VUE_APP_THEME}/image-paths`);

export default {
	name: 'DashboardCoreDrawer',
	components: {
		EmergencyDialog,
		VideoCallsList,
		ChatList,
		vueInternetChecker,
		Blink,
	},
	props: {
		expandOnHover: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		expand_reports: false,
		theme: process.env.VUE_APP_THEME,
		appVersion: process.env.VUE_APP_VERSION,
		logo: DARK_LOGO,
		items: [],
		myStyle: {
			background: process.env.VUE_APP_THEME === 'safebanker' ? 'linear-gradient(#557DBE, #3C5886)' : 'linear-gradient(#4e4e4e, #989898)',
			// background: 'linear-gradient(#557DBE, #3C5886)',
			// background: 'linear-gradient(#4e4e4e, #989898)',
		},
		emergencyDialog: false,
		webrtcErrorDialog: false,
		onLine: null,
		internetStatus: 'Connected',
	}),

	computed: {
		...mapState(['barColor', 'barImage', 'webrtcErrors']),
		...mapState({
			loggedUser: state => state.authentication.userObject,
			roleId: state => state.authentication.roleId,
			userInfos: state => state.authentication.userObject.UserInfos,
			organisations: state => state.organisation.organisations,
		}),
		androidAppVersion () {
			return this.loggedUser?.AppVersions?.find(
				app => app.Platform === 'ANDROID_APP_VERSION',
			)?.Version?.split(/[+(]/)?.[0]?.trim() || '';
		},
		iosAppVersion () {
			return this.loggedUser?.AppVersions?.find(
				app => app.Platform === 'IOS_APP_VERSION',
			)?.Version?.split(/[+(]/)?.[0]?.trim() || '';
		},
		trimmedAppVersion () {
			return this.appVersion.substring(1); // Remove the first character "v"
		},
		selectedOrganisation () {
			let organization;
			this.organisations.forEach(o => {
				if (o.Id === this.userInfos.OrganizationId) {
					organization = o;
				}
			});
			return organization;
		},
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DRAWER', val);
			},
		},
		computedItems () {
			return this.items.map(this.mapItem);
		},
		profile () {
			return {
				avatar: true,
				title: this.$t('avatar'),
			};
		},
		ens () {
			return this.roleId !== 1;
		},
	},

	watch: {
		roleId (val) {
			if (val !== null) {
				this.populateMenuItems();
				if (val === 12) {
					this.getOrganisations();
				}
			}
		},
	},

	methods: {
		status (ele) {
			this.onLine = ele;
		},
		event (ele) {
			this.internetStatus = ele.type === 'online' ? 'Connected' : 'Disconnected';
		},
		mapItem (item) {
			return {
				...item,
				children: item.children ? item.children.map(this.mapItem) : undefined,
				title: this.$t(item.title),
			};
		},
		getOrganisations () {
			this.$store.dispatch('organisation/get');
		},
		populateMenuItems () {
			// Populate drawer items based on role
			if (this.roleId !== 1) {	// Check if not MASTER (since master admin userobject does not have RoleId key)
				switch (this.roleId) {
				case 2:
					this.items = [
						{
							icon: 'mdi-view-dashboard',
							title: 'dashboard',
							to: '/',
						},
						{
							icon: 'mdi-poll',
							title: 'reports',
							to: '/reports',
							children: [
								{
									icon: 'mdi-calendar-today',
									to: '/daily',
									title: 'daily',
								},
								{
									icon: 'mdi-chart-timeline-variant',
									to: '/activities',
									title: 'activities',
								},
								{
									icon: 'mdi-check-all',
									to: '/checkinout',
									title: 'check-in-out',
								},
								{
									icon: 'mdi-clock',
									to: '/timerreports',
									title: 'timer',
								},
								{
									icon: 'mdi-playlist-check',
									to: '/surveyreports',
									title: 'survey',
								},
								{
									icon: 'mdi-note-edit',
									to: '/reports/logaccess',
									title: 'VaultTrak',
								},
							],
						},
						{
							icon: 'mdi-office-building',
							title: 'mainoffice',
							to: '/mainoffice',
						},
						{
							icon: 'mdi-bank',
							title: 'regions',
							to: '/regions',
						},
						{
							icon: 'mdi-store',
							title: 'locations',
							to: '/branches',
						},
						{
							icon: 'mdi-security',
							title: 'security-offices',
							to: '/security-offices',
						},
						{
							icon: 'mdi-account',
							title: 'employees',
							to: '/employees',
						},
						{
							icon: 'mdi-checkbox-multiple-marked',
							title: 'taskList	',
							to: '/task-list',
						},
						{
							icon: 'mdi-playlist-check',
							title: 'surveyList',
							to: '/survey-list',
						},
					];
					break;
				case 12:
					this.items = [
						{
							icon: 'mdi-view-dashboard',
							title: 'dashboard',
							to: '/',
						},
						{
							icon: 'mdi-poll',
							title: 'reports',
							to: '/reports',
							children: [
								{
									icon: 'mdi-calendar-today',
									to: '/daily',
									title: 'daily',
								},
								{
									icon: 'mdi-chart-timeline-variant',
									to: '/activities',
									title: 'activities',
								},
								{
									icon: 'mdi-check-all',
									to: '/checkinout',
									title: 'check-in-out',
								},
								{
									icon: 'mdi-clock',
									to: '/timerreports',
									title: 'timer',
								},
								{
									icon: 'mdi-playlist-check',
									to: '/surveyreports',
									title: 'survey',
								},
								{
									icon: 'mdi-note-edit',
									to: '/reports/logaccess',
									title: 'VaultTrak',
								},
							],
						},
						{
							icon: 'mdi-city',
							title: 'clients',
							to: '/clients',
						},
						{
							icon: 'mdi-office-building',
							title: 'mainoffice',
							to: '/mainoffice',
						},
						{
							icon: 'mdi-bank',
							title: 'regions',
							to: '/regions',
						},
						{
							icon: 'mdi-store',
							title: 'locations',
							to: '/branches',
						},
						{
							icon: 'mdi-security',
							title: 'security-offices',
							to: '/security-offices',
						},
						{
							icon: 'mdi-account',
							title: 'employees',
							to: '/employees',
						},
						{
							icon: 'mdi-checkbox-multiple-marked',
							title: 'taskList',
							to: '/task-list',
						},
						{
							icon: 'mdi-playlist-check',
							title: 'surveyList',
							to: '/survey-list',
						},
					];
					break;
				case 5:
					this.items = [
						{
							icon: 'mdi-view-dashboard',
							title: 'dashboard',
							to: '/',
						},
						{
							icon: 'mdi-poll',
							title: 'reports',
							// to: '/reports',
							children: [
								{
									icon: 'mdi-calendar-today',
									to: '/daily',
									title: 'daily',
								},
								{
									icon: 'mdi-chart-timeline-variant',
									to: '/activities',
									title: 'activities',
								},
								{
									icon: 'mdi-check-all',
									to: '/checkinout',
									title: 'check-in-out',
								},
								{
									icon: 'mdi-clock',
									to: '/timerreports',
									title: 'timer',
								},
								{
									icon: 'mdi-forum',
									to: '/chatrecordings',
									title: 'chat',
								},
								{
									icon: 'mdi-play-box',
									to: '/videorecordings',
									title: 'video',
								},
							],
						},
						{
							icon: 'mdi-bank',
							title: 'regions',
							to: '/regions',
						},
						{
							icon: 'mdi-store',
							title: 'locations',
							to: '/branches',
						},
						{
							icon: 'mdi-account',
							title: 'employees',
							to: '/employees',
						},
						{
							icon: 'mdi-security',
							title: 'security-offices',
							to: '/security-offices',
						},
					];
					break;
				case 8:
					this.items = [
						{
							icon: 'mdi-view-dashboard',
							title: 'dashboard',
							to: '/',
						},
						{
							icon: 'mdi-poll',
							title: 'reports',
							// to: '/reports',
							children: [
								{
									icon: 'mdi-calendar-today',
									to: '/daily',
									title: 'daily',
								},
								{
									icon: 'mdi-chart-timeline-variant',
									to: '/activities',
									title: 'activities',
								},
								{
									icon: 'mdi-check-all',
									to: '/checkinout',
									title: 'check-in-out',
								},
								{
									icon: 'mdi-clock',
									to: '/timerreports',
									title: 'timer',
								},
								{
									icon: 'mdi-forum',
									to: '/chatrecordings',
									title: 'chat',
								},
								{
									icon: 'mdi-play-box',
									to: '/videorecordings',
									title: 'video',
								},
							],
						},
						{
							icon: 'mdi-bank',
							title: 'regions',
							to: '/regions',
						},
						{
							icon: 'mdi-store',
							title: 'locations',
							to: '/branches',
						},
						{
							icon: 'mdi-security',
							title: 'security-offices',
							to: '/security-offices',
						},
					];
					break;
				default:
					this.items = [
						{
							icon: 'mdi-view-dashboard',
							title: 'dashboard',
							to: '/',
						},
						{
							icon: 'mdi-poll',
							title: 'reports',
							to: '/reports',
							children: [
								{
									icon: 'mdi-calendar-today',
									to: '/daily',
									title: 'daily',
								},
								{
									icon: 'mdi-chart-timeline-variant',
									to: '/activities',
									title: 'activities',
								},
								{
									icon: 'mdi-check-all',
									to: '/checkinout',
									title: 'check-in-out',
								},
								{
									icon: 'mdi-playlist-check',
									to: '/surveyreports',
									title: 'survey',
								},
								{
									icon: 'mdi-note-edit',
									to: '/reports/logaccess',
									title: 'VaultTrak',
								},
							],
						},
						{
							icon: 'mdi-bank',
							title: 'regions',
							to: '/regions',
						},
						{
							icon: 'mdi-store',
							title: 'locations',
							to: '/branches',
						},
						{
							icon: 'mdi-account',
							title: 'employees',
							to: '/employees',
						},
						{
							icon: 'mdi-checkbox-multiple-marked',
							title: 'taskList',
							to: '/task-list',
						},
						{
							icon: 'mdi-playlist-check',
							title: 'surveyList',
							to: '/survey-list',
						},
						{
							icon: 'mdi-security',
							title: 'security-offices',
							to: '/security-offices',
						},
					];
					break;
				}
			} else {
				this.items = [
					{
						icon: 'mdi-account',
						title: 'employees',
						to: '/admin/employees',
					},
					{
						icon: 'mdi-city',
						title: 'clients',
						to: '/admin/clients',
					},
					{
						icon: 'mdi-security',
						title: 'security-offices',
						to: '/admin/security-offices',
					},
				];
			}
		},
	},
	mounted () {
		this.populateMenuItems();
		if (this.roleId === 12) {
			this.getOrganisations();
		}
		this.expand_reports = this.$route.path.includes('/daily') ||
								this.$route.path.includes('/activities') ||
								this.$route.path.includes('/checkinout') ||
								this.$route.path.includes('/surveyreports') ||
								this.$route.path.includes('/timerreports') ||
								this.$route.path.includes('/chatrecordings') ||
								this.$route.path.includes('/videorecordings');
	},
};
</script>

<style scoped theme="safebanker">
/* #core-navigation-drawer {
	background: linear-gradient(#557DBE, #3C5886)
} */
</style>

<style scoped>
	.v-list.no-padding {
		padding: 0;
	}
</style>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px

</style>
