import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Client/Home.vue';
import AdminHome from '../views/Admin/Home.vue';
import Login from '../views/Client/Login.vue';
import AdminLogin from '../views/Admin/Login.vue';
import ForgotPassword from '../views/components/ForgotPassword.vue';
import ActivationEmail from '../views/components/ActivationEmail.vue';
import SurveyExternalUser from '../views/components/SurveyExternalUser';
import { requireAuth, logout } from './authentication';

/**
 * route level code-splitting
 * this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
 * component: () => import('..component Path'),
 */

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/admin/login',
		name: 'AdminLogin',
		component: AdminLogin,
	},
	{
		path: '/resetpassword/:token/:email',
		name: 'ForgotPassword',
		component: ForgotPassword,
	},
	{
		path: '/survey/:token/:id',
		name: 'SurveyExternalUser',
		component: SurveyExternalUser,
	},
	{
		path: '/validateToken/:token/:email',
		name: 'validateToken',
		component: ActivationEmail,
	},
	{
		path: '/logout',
		name: 'Logout',
		beforeEnter (to, from, next) {
			logout();
			next('/login');
		},
	},
	{
		path: '/admin/logout',
		name: 'AdminLogout',
		beforeEnter (to, from, next) {
			logout();
			next('/admin/login');
		},
	},
	{
		path: '/admin/',
		name: 'AdminDashboard',
		component: AdminHome,
		meta: { requiresAuth: 'admin' },
		children: [
			{
				path: '/admin/employees',
				name: 'AdminEmployees',
				component: () => import(/* webpackChunkName: "adminPages" */ '@/views/Admin/partials/Users.vue'),
				meta: { requiresAuth: 'admin' },
			},
			{
				path: '/admin/clients',
				name: 'AdminClients',
				component: () => import(/* webpackChunkName: "adminPages" */ '@/views/Admin/partials/Clients.vue'),
				meta: { requiresAuth: 'admin' },
			},
			{
				path: '/admin/security-offices',
				name: 'AdminSecurityOffices',
				component: () => import(/* webpackChunkName: "adminPages" */ '@/views/Admin/partials/SecurityOffices.vue'),
				meta: { requiresAuth: 'admin' },
			},
		],
	},
	{
		path: '/',
		component: Home,
		meta: {
			requiresAuth: true,
		},
		children: [
			// Dashboard
			{
				name: 'Dashboard',
				path: '',
				component: () => import(/* webpackChunkName: "dashboard" */ '@/views/partials/CADashboard'),
				meta: { requiresAuth: true },
				children: [
					// Notifications
					{
						name: 'Notifications',
						path: 'notifications/:type',
						component: () => import(/* webpackChunkName: "dashboard" */ '@/views/partials/Notifications'),
						meta: { requiresAuth: true },
					},
				],
			},
			{
				path: '/employees',
				name: 'Employees',
				component: () => import(/* webpackChunkName: "users" */ '@/views/partials/Users.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/employees/:id',
				name: 'Employee',
				component: () => import(/* webpackChunkName: "users" */ '@/views/partials/Users.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/reports',
				name: 'Reports',
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/Reports.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/daily',
				name: 'Daily',
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/DailyReports.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/activities',
				name: 'Activities',
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/ActivityReports.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/checkinout',
				name: 'CheckInOut',
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/CheckInOutReports.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/surveyreports',
				name: 'Survey',
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/SurveyReports.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/timerreports',
				name: 'Timer',
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/TimerReports.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/reports/logaccess',
				name: 'VaultTrak',
				component: () => import(/* webpackChunkName: "logaccess" */ '@/views/partials/reportsComponents/LogAccessReport.vue'),
				meta: { requiresAuth: true },
				children: [
					{
						name: 'VaultTrak details',
						path: '/reports/logaccess/:id',
						component: () => import(/* webpackChunkName: "logaccess" */ '@/views/partials/reportsComponents/LogAccessReportDetails.vue'),
						props: true,
						meta: { requiresAuth: true, menu: false },
					},
				],
			},
			// Recordings
			{
				path: '/chatrecordings',
				name: 'ChatRecordings',
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/chat/ChatRecordings.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/videorecordings',
				name: 'VideoRecordings',
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/video/VideoRecordings.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/clients',
				name: 'Clients',
				component: () => import(/* webpackChunkName: "clientPages" */ '@/views/partials/Clients.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/mainoffice',
				name: 'MainOffice',
				component: () => import(/* webpackChunkName: "clientPages" */ '@/views/partials/MainOffice.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/regions',
				name: 'Regions',
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/Regions.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/branches',
				name: 'Branches',
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/Branches.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/security-offices',
				name: 'SecurityOffices',
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/SecurityOffices.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/task-list',
				name: 'TaskList',
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/TaskList.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/survey-list',
				name: 'SurveyList',
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/SurveyList.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/survey-list/:id',
				name: 'SurveyListId',
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/SurveyList.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/chatrecording/:id',
				name: 'ChatRecording',
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/chat/ChatRecordingScreen.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/videorecording/:id',
				name: 'VideoRecording',
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/video/VideoRecordingScreen.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/chat/:id',
				name: 'Chat',
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/ChatScreen.vue'),
				meta: { requiresAuth: true },
			},
			{
				path: '/video/:id',
				name: 'Video',
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/VideoScreen.vue'),
				meta: { requiresAuth: true },
			},
		],
	},
];

const router = new VueRouter({
	mode: process.env.VUE_APP_NAVIGATION_MODE || 'hash',
	routes: routes,
});

router.beforeEach(requireAuth);

export default router;
