export const LOGIN_LOGO = {
	src: require('./logo_light.png'),
	width: '240px',
	height: '48px',
};

export const DARK_LOGO = {
	src: require('./logo_dark.png'),
	width: '240px',
	height: '48px',
};
