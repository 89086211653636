import { render, staticRenderFns } from "./EmergencyDialog.vue?vue&type=template&id=98e74666&scoped=true"
import script from "./EmergencyDialog.vue?vue&type=script&lang=js"
export * from "./EmergencyDialog.vue?vue&type=script&lang=js"
import style0 from "./EmergencyDialog.vue?vue&type=style&index=0&id=98e74666&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e74666",
  null
  
)

export default component.exports